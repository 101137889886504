.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    position: absolute;
    background: #f2f2f240;
    z-index: 999;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #38426a;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}