@import url(https://fonts.googleapis.com/css?family=Fredoka+One);
body {
	/* background-color: #f6f6f6; */
	background-color: #232e5b;
	font-family: "Saira Semi Condensed", sans-serif;
}

::-webkit-scrollbar {
	height: 4px;
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
	background: #acacac;
}

.page-wrap {
	height: 100vh;
	width: 100%;
}
.page-wrap .react-tabs {
	margin-top: 17px;
	box-shadow: 0 6px 22px 4px rgba(0, 0, 0, 0.01);
	margin-left: 5.333333%;
}
.page-wrap .react-tabs__tab {
	padding: 15px 45px;
	border: 0;
	color: #232e5b;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	width: 100%;
	text-align: center;
	border-radius: 0;
}
.page-wrap .react-tabs__tab--selected {
	background: #5d6584;
	color: #fff;
}
.page-wrap .react-tabs__tab-list {
	border-bottom: 1px solid #ccc;
	display: flex;
	justify-content: space-between;
	margin: 0;
	width: 99.44%;
	background: #38426a;
}
.main {
	padding-top: 20px;
}
.head-wrap {
	height: 70px;
	padding: 10px;
	width: 98%;
	flex-direction: row;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.head-wrap img {
	width: 110px;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.head-wrap h1 {
	font-size: 50px;
	background: -webkit-linear-gradient(#82c0cc, #232e5b);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding: 20px 15px;
	letter-spacing: 3px;
	text-align: center;
}

.prim-btn {
	background: #82c0cc;
	color: #fff !important;
	border: 0;
	box-shadow: 0 6px 22px 4px rgba(0, 0, 0, 0.01);
	font-weight: 500;
	background: #5d6584;
	width: 100%;
	border-radius: 0;
	border: 2px solid #41486a;
	padding: 13px 0;
	margin: 20px 0;
	font-size: 16px;
	transition: all 0.5s ease;
}
.prim-btn:hover {
	background: #82c0cc;
	background: linear-gradient(127deg, #232e5b 0, #82c0cc 87%);
	background: linear-gradient(225deg, #232e5b 47%, #82c0cc 81%);
	background: linear-gradient(225deg, #232e5b 117%, #82c0cc 69%);
	border: 2px solid transparent;
}
.reject:hover {
	background: #974d4d;
	background: linear-gradient(127deg, #850a0a 0, #cc828c 87%);
	background: linear-gradient(225deg, #cf0404 47%, #cc8282 81%);
	background: linear-gradient(225deg, #920a0a 117%, #cc8282 69%);
	border: 2px solid transparent;
}
.accept:hover {
	background: #69b264;
	background: linear-gradient(127deg, #06832c 0, #82cca0 87%);
	background: linear-gradient(225deg, #0d5f1c 47%, #82cc99 81%);
	background: linear-gradient(225deg, #21920a 117%, #82cc91 69%);
	border: 2px solid transparent;
}

.home-wrap h1 {
	text-align: center;
	font-size: 65px;
}

.outer-space {
	padding: 30px 10px 10px;
}

.choose-wrap {
	position: relative;
	z-index: 1;
	margin-bottom: 7px;
	margin-top: 0;
	box-shadow: 0 6px 22px 4px rgba(0, 0, 0, 0.1);
	background: #38426a;
}
.choose-wrap .choose-box {
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 14px;
	text-align: center;
}
.choose-wrap .choose-box input {
	position: relative;
	z-index: 1000;
	opacity: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	cursor: pointer;
}
.choose-wrap .img-box {
	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
	padding: 15px;
	position: relative;
	transition: top 0.5s ease-in-out;
}
.choose-wrap .img-box canvas {
	margin: auto;
	width: 100% !important;
	/* height: 90% !important; */
}
.choose-wrap .img-box .canvas-container {
	margin: auto;
	width: 100% !important;
	/* height: 90vh !important; */
}
.choose-wrap h5 {
	font-size: 20px;
	color: #212121;
	padding: 20px 15px;
	letter-spacing: 1px;
	text-align: center;
	z-index: 0;
	position: relative;
	top: 0;
	bottom: 0;
}

.action-box {
	background: #38426a;
	height: auto;
	padding: 5px;
	left: 0;
	right: 0;
	width: 93%;
	top: -7%;
	margin: auto;
	margin-bottom: 25px;
	margin-top: 25px;
}
.action-box .prim-btn {
	margin-bottom: 5px;
	align-items: center;
	display: flex;
	justify-content: center;
}
.action-box img {
	max-width: 20px;
	max-height: 20px;
	margin-right: 10px;
	filter: brightness(0) invert(1);
}

.btn-controls {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	align-items: center;
	position: absolute;
	left: -100px;
	transform: rotate(90deg);
	top: 0;
	bottom: 0;
	margin: auto;
}
.btn-controls .prim-btn {
	width: 40px;
	height: 40px;
	font-size: 20px;
	padding: 0;
	margin-right: 12px;
}

.pos-rel {
	position: relative;
}

.btn.prev-btn:disabled,
.btn.next-btn:disabled {
	background: inherit;
	border: inherit;
	pointer-events: auto;
	cursor: not-allowed;
	opacity: 0.5;
}

.start-card {
	padding-right: 25px;
	height: 85vh;
	overflow: auto;
}
.instruction-img {
	padding: auto;
	height: 100%;
	max-height: 50vh;
	width: 100%;
	padding: 30px;
	object-fit: contain;
}

.start-card .row-box {
	background: #38426a;
	margin: 0;
	padding: 20px 10px;
}
.start-card .card {
	margin-bottom: 16px;
}
.start-card .card .card-body img {
	width: 100%;
	height: 180px;
	-o-object-fit: cover;
	object-fit: cover;
}
.start-card .bot-btn {
	position: sticky;
	top: 0;
	bottom: -1px;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 100;
	background: #38426a;
	padding: 10px;
}

.img-thumbnail {
	width: 'auto';
	height: 300px;
	object-fit: cover;
	border-radius: 5%;
}

.pl30 {
	padding-left: 30px;
}

.pr30 {
	padding-right: 30px;
}

.content-wrap {
	background: #38426a;
	padding: 10px;
	overflow: auto;
	height: "auto";
	padding-top: 0 !important;
}
.content-wrap .label-head {
	background: #9398a9;
	padding: 10px;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}
.content-wrap .label-head h5 {
	color: #fff;
	font-size: 20px;
	text-align: center;
}
.content-wrap .label-box {
	/* background: #a0a4b5; */
	overflow: auto;
}
.content-wrap .label-txt {
	color: #fff;
	font-size: 14px;
	border-bottom: 1px solid;
	text-align: initial;
	padding: 12px;
	margin: 5px 0;
}

.editor-wrap .content-wrap {
	top: 34%;
	margin: auto;
	width: 93%;
	padding: 5px;
	height: 551px;
}
.editor-wrap .content-wrap .label-head {
	background: #9398a9;
}

.coords {
	color: #fff;
	display: flex;
	flex-wrap: wrap;
}

.back-btn {
	background: #38426a;
	padding: 5px;
	position: absolute;
	left: 0;
	right: 0;
	width: 88%;
	margin: auto;
	top: -7%;
} /*# sourceMappingURL=style.css.map */
